.navigation-text {
    cursor: pointer;
}

.info-hidden {
    opacity: 0;
}

.info-container {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: url('../assets/images/information-background.svg');
    background-size: cover;
    background-attachment: fixed;
    transition: opacity 3s;

    .name-container {
        width: fit-content;
        border-bottom: 1px solid white;
        display: flex;
        flex-direction: column;
        align-items: center;

        .name {
            font-size: 6.5em;
            color: white;
            line-height: normal;
        }
    }

    .description {
        padding-top: 10px;
        color: white;
    }
}

.experience-container {
    background-color: #f4efe0;
    padding: 65px 0px;

    @media (min-width: 1100px) and (min-height: 750px) {

        .section-title-sticky {
            position: sticky;
            top: 50%;
            transform: translateY(-50%);
            opacity: 1;
            transition: opacity 0.5s;
        }

        .experience-sticky {
            position: sticky;
            top: 0;
            height: 100vh;
            display: flex;
            justify-content: center;
            align-items: center;
            opacity: 1;
            transition: opacity 0.5s;
        }
    }

    .hidden {
        opacity: 0;
        pointer-events: none;
    }

    .experiences {
        width: 70vw;
        margin: 20px auto;

        .experience-row {
            padding: 25px 0px;

            .image-container {
                background-color: #f4efe0;
                padding: 0px 10px;

                img {
                    max-width: 100%;
                    max-height: 100%;
                }
            }

            .employer-name {
                color: lightseagreen;
            }

            .position-and-time {
                color: rgb(143, 143, 143);
            }

            p {
                color: #4d5055;
            }
        }
    }
}

.projects-education-container {
    background-color: white;
    padding-top: 40px;
    padding-bottom: 80px;
    display: flex;
    flex-direction: column;
    
    .projects-education-column {
        display: flex;
        flex-direction: column;
        margin: 30px 0px;

        .column-title {
            align-self: center;
            margin-bottom: 20px;
            color: #4d5055
        }

        @media (min-width: 992px) {

            .slider-container-grid {
                display: grid;

                .slide-grid {
                    grid-row-start: 1;
                    grid-column-start: 1;
                    grid-row-end: 1;
                    grid-column-end: 1;
                }
            }
        }

        .slider-container {

            .slide {
                display: flex;
                padding: 10px;
                border-radius: 20px;
                box-shadow: 5px 10px 18px #888888;
                position: relative;

                .arrow-container {
                    cursor: pointer;
                    top: 0;
                    bottom: 0;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    border-radius: 20px;

                    &.left {
                        margin-right: 10px;
                        padding: 0px 10px;
                    }

                    &.right {
                        margin-left: 10px;
                        padding: 0px 10px;
                    }

                    &:hover {
                        background-color: rgb(245, 245, 245);
                    }

                    .arrow-icon {
                        transform: scale(2);
                    }
                }

                .slider-content {
                    display: flex;
                    flex-direction: column;
                    word-break: break-word;

                    h1 {
                        color: lightseagreen;
                        text-align: center;
                    }

                    p {
                        color: #4d5055;
                    }

                    .spacer {
                        flex: 1;
                    }

                    .icon-row {
                        display: flex;
                        justify-content: flex-end;

                        a {
                            height: max-content;
                            margin-left: 10px;

                            img {
                                height: 40px;
                                width: auto;
                            }
                        }
                    }
                }
            }

            @media (min-width: 992px) {
                .inactive {
                    opacity: 0;
                    z-index: -1;
                }
            }

            @media (max-width: 991px) {
                .inactive {
                    display: none;
                }
            }
        }
    }
}

.contact-container {
    height: 20vh;
    background-color: #343a40;
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    a {
        height: 30%;
        width: 10%;

        img {
            height: 100%;
            width: 100%;
            -webkit-filter: invert(1);
            filter: invert(1);
        }
    }
}

@media (min-width: 651px) {

    .projects-education-row {
        margin: 0px 20px;
    }

    .section-title {

        h1 {
            display: flex;
            flex-direction: row;
            color: #348AA7;
        }
    
        h1:before, h1:after{
            content: "";
            flex: 1 1;
            border-bottom: 2px solid #348AA7;
            margin: auto;
        }
    
        h1:before {
            margin-left: 60px;
            margin-right: 10px
        }
    
        h1:after {
            margin-right: 60px;
            margin-left: 10px
        }
    }
}

@media (max-width: 650px) {

    .projects-education-row {
        margin: 0px;
    }

    .section-title {

        h1 {
            color: #348AA7;
            text-align: center;
        }
    }
}
